import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "tailwindcss/tailwind.css";
import heroImage from "../assets/career.png";

const CareerPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { ref: heroRef, inView: heroInView } = useInView({ triggerOnce: true });
  const { ref: internshipRef, inView: internshipInView } = useInView({ triggerOnce: true });
  const { ref: openRolesRef, inView: openRolesInView } = useInView({ triggerOnce: true });

  return (
    <div className="pt-14">
      {/* Hero Section */}
      <section
        ref={heroRef}
        className={`relative h-[43rem] flex items-center justify-center transition-opacity duration-1000 ${
          heroInView ? "opacity-100" : "opacity-0"
        }`}
      >
        <img className="absolute top-0 left-0 w-full h-full object-cover" src={heroImage} alt="Career Hero" />
        <div className="relative ml-4 mr-4 md:mx-auto z-10 bg-black bg-opacity-50 p-4 sm:p-6 rounded-lg text-center max-w-3xl mx-auto">
          <h1 className="text-xl text-white">"The only way to do great work is to love what you do." - Steve Jobs</h1>
        </div>
      </section>

      {/* Internship and Open Roles Section */}
      <section className="py-8 sm:py-16 bg-gray-100 text-center">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Internship Opportunities */}
            <div
              ref={internshipRef}
              className={`bg-white p-4 sm:p-6 rounded-lg shadow-lg transition-all duration-1000 ${
                internshipInView ? "opacity-100 transform translate-y-0" : "opacity-0 transform translate-y-10"
              }`}
            >
              <h2 className="text-2xl sm:text-3xl font-light mb-4 text-left">Internship Opportunities</h2>
              <h3 className="text-xl sm:text-2xl font-light mb-2 text-left">3-Month Internship Role</h3>
              <p className="text-gray-700 mb-4 text-justify">
                Join our team for a 3-month internship where you will gain hands-on experience, work on exciting
                projects, and learn from professionals. You will obtain comprehensive understanding of our operations
                and foster your professional growth.
              </p>
              <div className="text-left">
                <NavLink
                  to="/internship-details"
                  className="bg-blue-500 text-white px-4 py-2 sm:px-6 sm:py-3 rounded hover:bg-blue-600 transition duration-300 inline-block"
                >
                  Learn More
                </NavLink>
              </div>
            </div>
            {/* Open Roles */}
            <div
              ref={openRolesRef}
              className={`bg-white p-4 sm:p-6 rounded-lg shadow-lg transition-all duration-1000 ${
                openRolesInView ? "opacity-100 transform translate-y-0" : "opacity-0 transform translate-y-10"
              }`}
            >
              <h2 className="text-2xl sm:text-3xl font-light mb-4 text-left">Open Roles Available</h2>
              <h3 className="text-xl sm:text-2xl font-light mb-2 text-left">Full Stack Developer</h3>
              <p className="text-gray-700 mb-4 text-justify">
                We are looking for a skilled Full Stack Developer to join our team. You will be responsible for
                developing and maintaining web applications, collaborating with cross-functional teams, and ensuring the
                technical feasibility of UI/UX designs.
              </p>
              <div className="text-left">
                <NavLink
                  to="/fullstack-developer-details"
                  className="bg-blue-500 text-white px-4 py-2 sm:px-6 sm:py-3 rounded hover:bg-blue-600 transition duration-300 inline-block"
                >
                  Learn More
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CareerPage;
