import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6 text-xs md:text-base">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 Garlic Capital Corporation. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
