import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "tailwindcss/tailwind.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import jason from "../assets/jason.jpeg";
import johnson from "../assets/johnson.jpg";
import yb from "../assets/yb.png";

const AboutUs = () => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-14">
      {/* Mandate */}
      <section className=" py-16 transition-all duration-1000 bg-gray-800 opacity-90 text-white text-center">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-6 md:max-w-2xl"
        >
          <h2 className="text-3xl sm:text-4xl font-light mb-6 mt-4">Our Mandate</h2>
          <p className="text-base sm:text-lg leading-relaxed mb-6 text-justify">
            At Garlic Capital, our mandate is to achieve superior investment performance through proprietary trading and
            innovative financial strategies. We are dedicated to leveraging cutting-edge technology and quantitative
            algorithms to capitalize on extraordinary market events and generate alpha returns for our clients and
            stakeholders. Our commitment to excellence, integrity, and transparency drives our vision to redefine
            trading and investment standards in the financial industry.
          </p>
        </motion.div>
      </section>

      {/* Meet the Team */}
      <section className="py-16 bg-gray-100 text-center transition-all duration-1000">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
          transition={{ duration: 0.8, staggerChildren: 0.3 }}
          className="container mx-auto px-6 md:max-w-4xl"
        >
          <h2 className="text-3xl sm:text-4xl font-light mb-6">Meet the Team</h2>
          <div className="space-y-16">
            {[
              {
                img: yb,
                name: "Yang Bin",
                desc: "Yang Bin is the rainmaker of Garlic Capital. He was co-founder of Zopim, a Singapore-based messaging company that was acquired by Zendesk (NYSE-listed) in 2014. With a strong background in entrepreneurship and finance, Kwok has been instrumental in driving the strategic vision and growth of Garlic Capital. His extensive experience in the tech industry and successful exit from Zopim provide invaluable insights and leadership to the company.",
                linkedin: "https://www.linkedin.com/in/yang-bin-kwok-0592982/",
              },
              {
                img: johnson,
                name: "Johnson Chin",
                desc: "Johnson is our quant trader. He has years of experience in software engineering at Zendesk and CitaDAO. His technical expertise and experience in scaling technology solutions make him a valuable asset to Garlic Capital.",
                linkedin: "https://www.linkedin.com/in/johnson-chin/",
              },
              {
                img: jason,
                name: "Jason Chin",
                desc: "Jason makes things happen at Garlic Capital. He is responsible for the day-to-day operations at Garlic Capital, ensuring the smooth execution of the firm's strategies and business activities. His leadership and operational expertise have been critical in driving the company forward.",
                linkedin: "https://www.linkedin.com/in/jasonchinjc/",
              },
            ].map((member, index) => (
              <motion.div
                key={index}
                variants={fadeInUp}
                className="flex flex-col items-center mx-auto px-4 sm:px-6 lg:px-8 max-w-full"
              >
                <img src={member.img} alt={member.name} className="w-24 h-24 sm:w-40 sm:h-40 object-cover rounded" />
                <div className="flex items-center mt-4">
                  <h3 className="text-lg sm:text-xl font-semibold">{member.name}</h3>
                  <a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline ml-2 inline-block"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
                <p className="text-gray-700 text-center mt-4 mb-4 text-justify">{member.desc}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default AboutUs;
