import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import DataContext from "./context/DataContext";
import NavBar from "./components/NavBar";
import Homepage from "./components/Homepage";
import AboutUs from "./components/AboutUs";
import Solutions from "./components/Solutions";
import Careers from "./components/Careers";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import InternshipDetails from "./components/InternshipDetails";
import FullStackDeveloperDetails from "./components/FullStackDeveloperDetails";

const App = () => {
  return (
    <div className="font-sans font-light min-h-screen flex flex-col">
      <DataContext.Provider value={{}}>
        <NavBar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/internship-details" element={<InternshipDetails />} />
            <Route path="/fullstack-developer-details" element={<FullStackDeveloperDetails />} />
          </Routes>
        </div>
        <Footer />
      </DataContext.Provider>
    </div>
  );
};

export default App;
