import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "tailwindcss/tailwind.css";
import videoSrc from "../assets/hero.mp4";
import { FaChartLine, FaSearch, FaShieldAlt, FaLaptopCode, FaGlobe } from "react-icons/fa";

// Importing company logos
import CircleLogo from "../assets/circle.png";
import BinanceLogo from "../assets/binance.svg";
import KrakenLogo from "../assets/kraken.svg";
import SinegyLogo from "../assets/sinegy.png";
import DelchainLogo from "../assets/delchain.svg";
import OkxLogo from "../assets/okx.png";

const HomePage = () => {
  const { ref: heroRef, inView: heroInView } = useInView({ triggerOnce: true });
  const { ref: solutionsRef, inView: solutionsInView } = useInView({ triggerOnce: true });
  const { ref: contactRef, inView: contactInView } = useInView({ triggerOnce: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-14">
      {/* Hero Section */}
      <section
        ref={heroRef}
        className={`relative md:h-[80vh] h-screen flex items-center justify-center transition-all duration-1000 ${
          heroInView ? "opacity-100" : "opacity-0"
        }`}
      >
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div className="relative z-10 p-6 rounded-lg text-center ml-4 mr-4 md:mx-auto md:max-w-3xl mx-auto bg-gray-800 opacity-80">
          <p className="md:text-xl text-lg text-white z-10 mt-4">
            Garlic Capital specializes in generating alpha returns through proprietary trading. Utilizing quantitative
            algorithms and taking advantage of extraordinary market events, the firm is committed to excellence and
            innovation in the financial industry.
          </p>
          <NavLink
            to="/aboutus"
            className="mt-6 bg-blue-500 text-white px-6 py-3 rounded hover:bg-gray-600 transition duration-300 inline-block"
          >
            Learn More
          </NavLink>
        </div>
      </section>

      {/* Solutions Section */}
      <section
        ref={solutionsRef}
        className={`py-16 bg-gray-100 text-center transition-all duration-1000 ${
          solutionsInView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        }`}
      >
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-light mb-6">Our Solutions</h2>
          <div className="flex flex-wrap justify-center">
            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                <FaChartLine className="text-6xl text-blue-500 mb-4 animate-pulse hover:animate-pulse" />
                <h3 className="text-xl font-light mb-4">Quantitative Trading</h3>
                <p className="text-gray-700 text-center">
                  Advanced algorithms execute high-frequency trades to generate consistent returns. Trading strategies
                  are designed to address market inefficiencies and provide liquidity.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                <FaSearch className="text-6xl text-blue-500 mb-4 hover:animate-pulse" />
                <h3 className="text-xl font-light mb-4">Market Analysis</h3>
                <p className="text-gray-700 text-center">
                  In-depth analysis identifies unique opportunities and mitigates risks. Both fundamental and technical
                  analysis are employed to forecast market trends and make informed trading decisions.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                <FaShieldAlt className="text-6xl text-blue-500 mb-4 animate-pulse" />
                <h3 className="text-xl font-light mb-4">Risk Management</h3>
                <p className="text-gray-700 text-center">
                  Effective strategies protect investments and maximize returns. This approach includes stress testing,
                  scenario analysis, and the use of sophisticated risk metrics.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                <FaLaptopCode className="text-6xl text-blue-500 mb-4 animate-pulse" />
                <h3 className="text-xl font-light mb-4">Algorithm Development</h3>
                <p className="text-gray-700 text-center">
                  Custom algorithms are developed to meet specific trading strategies, leveraging the latest in
                  technology and financial theories.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                <FaGlobe className="text-6xl text-blue-500 mb-4 animate-pulse" />
                <h3 className="text-xl font-light mb-4">Global Research</h3>
                <p className="text-gray-700 text-center">
                  Comprehensive research from global markets to identify trends and opportunities, ensuring a
                  well-rounded and informed trading approach.
                </p>
              </div>
            </div>
          </div>
          <NavLink to="/solutions" className="text-blue-500 hover:underline mt-6 block">
            Explore our solutions
          </NavLink>
        </div>
      </section>

      {/* Our Partners Section */}
      <section className="py-16 bg-white text-center w-screen">
        <div className="container mx-auto space-y-4 md:space-y-10">
          <h2 className="text-3xl font-light">Liquidity Venues</h2>
          <div className="pt-5 flex-col flex space-y-10 md:space-y-0 md:flex-row flex-wrap justify-center items-center md:p-0 md:space-x-20">
            {" "}
            <div>
              <img src={OkxLogo} className="h-6 sm:h-10" />
            </div>
            <div>
              <img src={SinegyLogo} className="h-8 sm:h-10" />
            </div>{" "}
            <div>
              <img src={KrakenLogo} className="h-4 sm:h-6" />
            </div>
            <div>
              <img src={CircleLogo} className="h-7 sm:h-10" />
            </div>
            <div>
              <img src={BinanceLogo} className="h-7 sm:h-10" />
            </div>
            <div>
              <img src={DelchainLogo} className="h-10 sm:h-12" />
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Section */}
      <section
        ref={contactRef}
        className={`py-16 bg-gray-200 text-center transition-all duration-1000 ${
          contactInView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        }`}
      >
        <div className="container mx-auto px-6 max-w-3xl">
          <h2 className="text-3xl font-light mb-6">Contact Us</h2>
          <p className="text-gray-700 mb-6">
            Have questions or want to get in touch? Contact Garlic Capital for assistance.
          </p>
          <NavLink to="/contactus" className="text-blue-500 hover:underline">
            Get in touch
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
