import React, { useState, useContext, useEffect } from "react";
import DataContext from "../context/DataContext";
import { NavLink } from "react-router-dom";
import Logo from "../assets/logo3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const NavBar = () => {
  const data = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const [navBackground, setNavBackground] = useState("bg-transparent");
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavBackground("bg-white shadow-md");
      } else {
        setNavBackground("bg-transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="fixed w-full z-50 bg-white">
      <nav className="md:mt-4  border-b-2 border-slate-100">
        {/* //=============================== Layout for smaller screens */}
        <div className="flex items-center justify-between px-4 py-2 md:hidden">
          <NavLink to="/" onClick={handleLinkClick} className="md:hidden flex-grow-0 flex-shrink-0">
            <img src={Logo} alt="Logo" className="h-[6rem] mx-auto mt-[-1.5rem] mb-[-2rem]" />
          </NavLink>
          <button className="md:hidden text-2xl mt-[0.2rem]" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </button>
        </div>
        {/* //=============================== Layout for larger screens */}
        {/* The hidden class sets display: none,
        ensuring that the menu is not visible by default on small screens. */}
        {/* The md:flex class overrides the hidden class starting from medium screen sizes (768px and up). */}
        <ul
          className={`${
            isOpen ? "block" : "hidden"
          } md:text-sm md:flex h-full list-none items-center justify-between space-y-4 md:space-y-0 px-4 md:px-12  mb-[0.5rem] md:pr-[4rem] md:pl-[4rem] md:mt-[-3rem] md:mb-[-3rem]`}
        >
          <div className="mb-[1.25rem] md:mb-0 flex w-full flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-8">
            <li className="flex-grow-0 flex-shrink-0">
              <NavLink to="/" onClick={handleLinkClick}>
                <img src={Logo} alt="Logo" className="h-12 md:h-[10rem] hidden md:block" />
              </NavLink>
            </li>
            <div className="flex flex-col items-center space-y-6 md:flex-row md:space-y-0 md:space-x-8">
              <li>
                <NavLink
                  to="/aboutus"
                  onClick={handleLinkClick}
                  className={({ isActive }) =>
                    isActive
                      ? "text-black font-bold opacity-50"
                      : "text-gray-500 opacity-75 hover:opacity-100 hover:text-black transition duration-300"
                  }
                >
                  ABOUT US
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/solutions"
                  onClick={handleLinkClick}
                  className={({ isActive }) =>
                    isActive
                      ? "text-black font-bold opacity-50"
                      : "text-gray-500 opacity-75 hover:opacity-100 hover:text-black transition duration-300"
                  }
                >
                  SOLUTIONS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/careers"
                  onClick={handleLinkClick}
                  className={({ isActive }) =>
                    isActive
                      ? "text-black font-bold opacity-50"
                      : "text-gray-500 opacity-75 hover:opacity-100 hover:text-black transition duration-300"
                  }
                >
                  CAREERS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contactus"
                  onClick={handleLinkClick}
                  className={({ isActive }) =>
                    isActive
                      ? "text-black font-bold opacity-50"
                      : "text-gray-500 opacity-75 hover:opacity-100 hover:text-black transition duration-300"
                  }
                >
                  CONTACT US
                </NavLink>
              </li>
            </div>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
