import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const InternshipDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-14">
      <section className=" md:mt-[1rem] py-16 bg-gray-100 text-center">
        <div className="container mx-auto px-6 md:px-12">
          <h2 className="text-4xl font-light mb-10">3-Month Internship Role</h2>
          <p className="text-gray-700 text-justify mb-6">
            We are offering a 3-month internship opportunity where you will gain valuable experience in the industry. As
            an intern, you will work on exciting projects, learn from experienced professionals, and develop your skills
            in a supportive environment.
          </p>
          <p className="text-gray-700 text-justify mb-6">
            <strong>Responsibilities:</strong>
            <ul className="list-disc list-inside text-left md:text-justify">
              <li>Assist in project development and implementation</li>
              <li>Participate in team meetings and brainstorming sessions</li>
              <li>Learn and apply industry best practices</li>
              <li>Collaborate with team members on various tasks</li>
              <li>Conduct comprehensive market research and analyze industry trends</li>
            </ul>
          </p>
          <p className="text-gray-700 text-justify mb-6">
            <strong>Requirements:</strong>
            <ul className="list-disc list-inside text-left md:text-justify">
              <li>
                Currently enrolled in a reputable undergraduate or postgraduate program in business, finance, or
                accounting with strong academic achievement
              </li>
              <li>Strong passion for business and finance, with effective written and verbal communication skills</li>
              <li>Good communication and teamwork skills</li>
              <li>Basic knowledge of industry tools and technologies</li>
            </ul>
          </p>
          <p className="text-gray-700 text-justify mb-6">
            If you are interested in this internship opportunity, please send your resume and cover letter to{" "}
            <a href="mailto:careers@garliccapital.com" className="text-blue-500 hover:underline">
              careers@garliccapital.com
            </a>
            .
          </p>
          <NavLink to="/careers" className="text-blue-500 hover:underline">
            &lt; Back
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default InternshipDetails;
