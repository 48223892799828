import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "tailwindcss/tailwind.css";
import videoSrc from "../assets/solutions.mp4";
import { FaChartLine, FaLaptopCode, FaShieldAlt, FaSearch, FaGlobe } from "react-icons/fa";

// Placeholder images
import Image1 from "../assets/quant-trading.png";
import Image2 from "../assets/market-analysis.png";
import Image3 from "../assets/risk-management.png";
import Image4 from "../assets/dev.png";
import Image5 from "../assets/market-research.png";

const SolutionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { ref: section1Ref, inView: section1InView } = useInView({ triggerOnce: true });
  const { ref: section2Ref, inView: section2InView } = useInView({ triggerOnce: true });
  const { ref: section3Ref, inView: section3InView } = useInView({ triggerOnce: true });
  const { ref: section4Ref, inView: section4InView } = useInView({ triggerOnce: true });
  const { ref: section5Ref, inView: section5InView } = useInView({ triggerOnce: true });

  return (
    <div className="pt-14">
      {/* Hero Section */}
      <section className="relative h-[43rem] flex items-center justify-center">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div className="relative ml-4 mr-4 md:mx-auto z-10 bg-black bg-opacity-50 p-6 rounded-lg text-center max-w-3xl mx-auto">
          <h1 className="text-xl text-white">We add value whether you are in the startup or growth phase.</h1>
        </div>
      </section>

      {/* Solutions Section */}
      <section className="py-16 bg-gray-100 text-center">
        <div className="container mx-auto px-6 space-y-16">
          <div
            ref={section1Ref}
            className={`flex flex-col md:flex-row items-center transition-all duration-1000 ${
              section1InView ? "opacity-100 transform translate-y-0" : "opacity-0 transform -translate-y-20"
            }`}
          >
            <div className="w-full md:w-1/2 p-4 hidden md:block">
              <img src={Image1} alt="Algorithmic Trading" className="rounded-lg shadow-lg w-full h-64 object-cover" />
            </div>
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
              <FaChartLine className="text-4xl text-blue-500 mb-4 hidden lg:block" />
              <h3 className="text-2xl font-light mb-4">1. Algorithmic Trading</h3>
              <p className="text-gray-700 text-justify">
                Garlic Capital's algorithmic trading strategies leverage sophisticated quantitative algorithms to
                exploit market inefficiencies and optimize trading performance. Whether you are in the startup phase
                looking for initial growth or in the growth phase seeking to maximize returns, our high-frequency and
                strategic trading methods ensure consistent and maximized returns, providing liquidity and enhancing
                your market positioning.
              </p>
            </div>
          </div>

          <div
            ref={section2Ref}
            className={`flex flex-col md:flex-row items-center transition-all duration-1000 ${
              section2InView ? "opacity-100 transform translate-y-0" : "opacity-0 transform translate-y-20"
            }`}
          >
            <div className="w-full md:w-1/2 p-4 order-last md:order-first hidden md:block">
              <img src={Image2} alt="Market Analysis" className="rounded-lg shadow-lg w-full h-64 object-cover" />
            </div>
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
              <FaSearch className="text-4xl text-blue-500 mb-4 hidden lg:block" />
              <h3 className="text-2xl font-light mb-4">2. Comprehensive Market Analysis</h3>
              <p className="text-gray-700 text-justify">
                Our comprehensive market analysis combines both fundamental and technical approaches to identify unique
                opportunities and provide actionable insights. For startups, this means uncovering new opportunities and
                ensuring a solid foundation for growth. For established businesses in the growth phase, our analysis
                helps mitigate risks and maximize returns, ensuring you remain ahead in the market.
              </p>
            </div>
          </div>

          <div
            ref={section3Ref}
            className={`flex flex-col md:flex-row items-center transition-all duration-1000 ${
              section3InView ? "opacity-100 transform translate-y-0" : "opacity-0 transform -translate-y-20"
            }`}
          >
            <div className="w-full md:w-1/2 p-4 hidden md:block">
              <img src={Image3} alt="Risk Management" className="rounded-lg shadow-lg w-full h-64 object-cover" />
            </div>
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
              <FaShieldAlt className="text-4xl text-blue-500 mb-4 hidden lg:block" />
              <h3 className="text-2xl font-light mb-4">3. Advanced Risk Management</h3>
              <p className="text-gray-700 text-justify">
                Our advanced risk management strategies are designed to protect investments and maximize returns through
                rigorous stress testing, scenario analysis, and the use of sophisticated risk metrics. For startups,
                this means safeguarding your initial investments and navigating market volatility. For growing
                businesses, our proactive approach helps maintain stability and promote sustained growth in an
                ever-changing market.
              </p>
            </div>
          </div>

          <div
            ref={section4Ref}
            className={`flex flex-col md:flex-row items-center transition-all duration-1000 ${
              section4InView ? "opacity-100 transform translate-y-0" : "opacity-0 transform translate-y-20"
            }`}
          >
            <div className="w-full md:w-1/2 p-4 order-last md:order-first hidden md:block">
              <img src={Image4} alt="Algorithm Development" className="rounded-lg shadow-lg w-full h-64 object-cover" />
            </div>
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
              <FaLaptopCode className="text-4xl text-blue-500 mb-4 hidden lg:block" />
              <h3 className="text-2xl font-light mb-4">4. Custom Algorithm Development</h3>
              <p className="text-gray-700 text-justify">
                We specialize in developing custom trading algorithms tailored to specific strategies and market
                conditions. For startups, this provides a unique edge in the market with innovative solutions. For
                businesses in the growth phase, our custom algorithms enhance existing strategies, ensuring continued
                competitive advantage and fostering growth.
              </p>
            </div>
          </div>

          <div
            ref={section5Ref}
            className={`flex flex-col md:flex-row items-center transition-all duration-1000 ${
              section5InView ? "opacity-100 transform translate-y-0" : "opacity-0 transform -translate-y-20"
            }`}
          >
            <div className="w-full md:w-1/2 p-4 hidden md:block">
              <img src={Image5} alt="Global Research" className="rounded-lg shadow-lg w-full h-64 object-cover" />
            </div>
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
              <FaGlobe className="text-4xl text-blue-500 mb-4 hidden lg:block" />
              <h3 className="text-2xl font-light mb-4">5. Global Market Research</h3>
              <p className="text-gray-700 text-justify">
                Our global market research provides comprehensive insights from markets around the world, helping to
                identify trends and opportunities for a well-rounded trading approach. For startups, this means access
                to vital market information and trends to build a strong foundation. For established businesses, our
                continuous monitoring and analysis ensure that you stay informed and adapt to changing market
                conditions, ensuring long-term success and profitability.
              </p>
            </div>
          </div>

          <div className="text-center mt-8">
            <p className="text-gray-700 mb-6">
              Have questions or want to get in touch? Contact Garlic Capital for assistance.
            </p>
            <NavLink to="/contactus" className="text-blue-500 hover:underline">
              Get in touch
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SolutionsPage;
