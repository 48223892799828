import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    enquiry: "",
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://garlic-backend.vercel.app/api/send-email", {
        // Replace with your actual backend URL
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Success:", await response.json());
        console.log("HELLO?");
        setShowModal(true);
        setFormData({
          name: "",
          email: "",
          enquiry: "",
        });
      } else {
        console.error("Error:", await response.text());
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="pt-20">
      <div className="text-center mb-10 mt-[4rem] px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl">Interested in Working with Us?</h1>
        <p className="text-gray-600 mt-4 md:mb-0 mb-[-1.7rem]">Get in touch with us today!</p>
      </div>
      <div className="max-w-md mx-auto my-10 p-8 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="enquiry" className="block text-sm font-medium text-gray-700">
              Enquiry
            </label>
            <textarea
              name="enquiry"
              id="enquiry"
              value={formData.enquiry}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-transform transform hover:scale-105"
          >
            Send
          </button>
        </form>
      </div>

      {/* Modal */}
      <Transition show={showModal} as={React.Fragment}>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0 transition-opacity"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
            >
              <h3 className="text-lg font-medium leading-6 text-gray-900">Thank you!</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">Your enquiry has been submitted. We will get back to you soon!</p>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default ContactUs;
