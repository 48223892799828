import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const FullStackDeveloperDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-14">
      <section className="md:mt-[1rem] py-16 bg-gray-100 text-center">
        <div className="container mx-auto px-6 md:px-12">
          <h2 className="text-4xl font-light mb-10">Full Stack Developer</h2>
          <p className="text-gray-700 text-justify mb-6">
            We are looking for a skilled Full Stack Developer to join our team. In this role, you will be responsible
            for developing and maintaining web applications, collaborating with cross-functional teams, and ensuring the
            technical feasibility of UI/UX designs.
          </p>
          <p className="text-gray-700 text-justify mb-6">
            <strong>Responsibilities:</strong>
            <ul className="list-disc list-inside text-left md:text-justify">
              <li>Develop and maintain web applications</li>
              <li>Collaborate with cross-functional teams to define and implement new features</li>
              <li>Ensure the technical feasibility of UI/UX designs</li>
              <li>Optimize applications for maximum speed and scalability</li>
              <li>Troubleshoot and debug applications</li>
            </ul>
          </p>
          <p className="text-gray-700 text-justify mb-6">
            <strong>Requirements:</strong>
            <ul className="list-disc list-inside text-left md:text-justify">
              <li>Proven experience as a Full Stack Developer or similar role</li>
              <li>Strong knowledge of front-end and back-end technologies</li>
              <li>Experience with JavaScript, HTML, CSS, and relevant frameworks</li>
              <li>Familiarity with database technology</li>
              <li>Excellent communication and teamwork skills</li>
            </ul>
          </p>
          <p className="text-gray-700 text-justify mb-6">
            If you are interested in this position, please send your resume and cover letter to{" "}
            <a href="mailto:careers@garliccapital.com" className="text-blue-500 hover:underline">
              careers@garliccapital.com
            </a>
            .
          </p>
          <NavLink to="/careers" className="text-blue-500 hover:underline">
            &lt; Back
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default FullStackDeveloperDetails;
